import React, { useRef, useState, useReducer, useMemo } from "react";
import { useReactToPrint } from "react-to-print";
import { FaSquarePlus } from "react-icons/fa6";
import { ImCross } from "react-icons/im";
import NavigationBreadcrumb from "./utils/NavigationBreadcrumb";
import SEO from "../../../../components/SEO";
import incomeSourceImg from "../../../../images/income-verification.png";
import currencies from "./all_currencies.json";

// Custom hook for currency management
const useCurrencyManager = () => {
  const [verificationCurrency, setVerificationCurrency] = useState("");
  const [verificationRate, setVerificationRate] = useState("");

  // Initialize exchange rates with NPR=1 and others empty
  const initialExchangeRates = currencies.reduce((acc, currency) => {
    acc[currency.code] = currency.code === "NPR" ? 1 : "";
    return acc;
  }, {});

  const [exchangeRates, setExchangeRates] = useState(initialExchangeRates);

  // When verification currency/rate changes, update the exchange rates
  const updateVerificationCurrency = (currency, rate) => {
    setVerificationCurrency(currency);
    setVerificationRate(rate);

    if (currency && rate) {
      setExchangeRates(prev => ({
        ...prev,
        [currency]: Number(rate)
      }));
    }
  };

  return {
    verificationCurrency,
    verificationRate,
    exchangeRates,
    updateVerificationCurrency,
    setExchangeRates
  };
};

// Reducer for form state management
const formReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_PERSONAL_INFO':
      return { ...state, personalInfo: { ...state.personalInfo, ...action.payload } };
    case 'UPDATE_INCOME_SOURCE':
      const updatedSources = [...state.incomeSources];
      updatedSources[action.index] = { ...updatedSources[action.index], ...action.payload };
      return { ...state, incomeSources: updatedSources };
    case 'ADD_INCOME_SOURCE':
      return {
        ...state,
        incomeSources: [
          ...state.incomeSources,
          {
            incomeSource: "",
            owner: "",
            mr: false,
            mrs: false,
            ms: false,
            annualIncome: "",
            currency: "NPR",
            remarks: ""
          }
        ]
      };
    case 'REMOVE_INCOME_SOURCE':
      return {
        ...state,
        incomeSources: state.incomeSources.filter((_, i) => i !== action.index)
      };
    default:
      return state;
  }
};

const IncomeSourceCertificate = () => {
  const previewRef = useRef();

  // Initialize form state
  const initialState = {
    personalInfo: {
      name: "",
      wardChairPersonName: "",
      wardChairPersonPhone: "",
      wardChairPersonEmail: "",
      mr: false,
      mrs: false,
      ms: false,
      fatherName: "",
      motherName: "",
      localgovernment: "",
      wardNo: "",
      district: "",
      province: "",
      date: "",
      fiscalyear: "",
      municipality: false,
      ruralmuncipality: false,
      submetropolitan: false,
      metropolitan: false,
    },
    incomeSources: [
      {
        incomeSource: "",
        owner: "",
        mr: false,
        mrs: false,
        ms: false,
        annualIncome: "",
        currency: "NPR",
        remarks: ""
      }
    ]
  };

  const [state, dispatch] = useReducer(formReducer, initialState);
  const {
    verificationCurrency,
    verificationRate,
    exchangeRates,
    updateVerificationCurrency,
    setExchangeRates
  } = useCurrencyManager();

  // Calculate totals with currency conversion
  const totals = useMemo(() => {
    const result = {
      NPR: 0,
      byCurrency: {}
    };

    state.incomeSources.forEach(info => {
      const amount = Number(info.annualIncome) || 0;
      const currency = info.currency;

      if (!result.byCurrency[currency]) {
        result.byCurrency[currency] = {
          amount: 0,
          nprEquivalent: 0
        };
      }

      result.byCurrency[currency].amount += amount;

      const rate = exchangeRates[currency] || 0;
      const nprValue = amount * rate;
      result.byCurrency[currency].nprEquivalent += nprValue;
      result.NPR += nprValue;
    });

    return result;
  }, [state.incomeSources, exchangeRates]);

  // Calculate total in verification currency
  const verificationTotal = useMemo(() => {
    if (!verificationCurrency || !verificationRate || verificationRate <= 0) return 0;
    return totals.NPR / Number(verificationRate);
  }, [totals.NPR, verificationCurrency, verificationRate]);

  // Handler functions
  const handlePersonalInfoChange = (e) => {
    dispatch({
      type: 'UPDATE_PERSONAL_INFO',
      payload: { [e.target.name]: e.target.value }
    });
  };

  const handleRadioChange = (e) => {
    const { value } = e.target;
    dispatch({
      type: 'UPDATE_PERSONAL_INFO',
      payload: {
        mr: value === "mr",
        mrs: value === "mrs",
        ms: value === "ms"
      }
    });
  };

  const handleLocalLevelRadioChange = (e) => {
    const { value } = e.target;
    dispatch({
      type: 'UPDATE_PERSONAL_INFO',
      payload: {
        municipality: value === "municipality",
        ruralmuncipality: value === "ruralmuncipality",
        submetropolitan: value === "submetropolitan",
        metropolitan: value === "metropolitan"
      }
    });
  };

  const handleIncomeChange = (e, index) => {
    dispatch({
      type: 'UPDATE_INCOME_SOURCE',
      index,
      payload: { [e.target.name]: e.target.value }
    });
  };

  const handleRadioChangeForIncome = (e, index) => {
    const { value } = e.target;
    dispatch({
      type: 'UPDATE_INCOME_SOURCE',
      index,
      payload: {
        mr: value === "mr",
        mrs: value === "mrs",
        ms: value === "ms"
      }
    });
  };

  const handleAddIncomeSource = () => {
    dispatch({ type: 'ADD_INCOME_SOURCE' });
  };

  const handleDeleteIncomeSource = (index) => {
    dispatch({ type: 'REMOVE_INCOME_SOURCE', index });
  };

  const handleVerificationCurrencyChange = (e) => {
    const currency = e.target.value;
    const rate = currency === verificationCurrency ? verificationRate : "";
    updateVerificationCurrency(currency, rate);
  };

  const handleVerificationRateChange = (e) => {
    const rate = e.target.value;
    updateVerificationCurrency(verificationCurrency, rate);
  };

  const handleExchangeRateChange = (currency, value) => {
    // Prevent changing NPR rate (always 1)
    if (currency === "NPR") return;

    // If changing the verification currency rate, update both
    if (currency === verificationCurrency) {
      updateVerificationCurrency(currency, value);
    } else {
      setExchangeRates(prev => ({
        ...prev,
        [currency]: value
      }));
    }
  };

  const getCurrencySymbol = (code) => {
    const currency = currencies.find(c => c.code === code);
    return currency ? currency.symbol : code;
  };

  const getCurrencyName = (code) => {
    const currency = currencies.find(c => c.code === code);
    return currency ? currency.name : code;
  };

  const handlePrint = useReactToPrint({
    content: () => previewRef.current,
    documentTitle: "income_source_certificate",
    pageStyle: `
      @page { 
        size: A4; 
        margin: 20px;
      }
      body { 
        margin: 20px; 
      }
    `,
  });

  return (
    <>
      <NavigationBreadcrumb
        slug={"income-source-certificate"}
        name={"Income Source Certificate"}
      />
      <SEO
        title={"Income Source Certificate Builder"}
        description={"Build Income Source Certificate, Income Source Letter Template Nepal."}
        name={"Income Source Certificate Builder"}
        type={"website"}
        path={"/tools/documents/income-source-certificate"}
        image={incomeSourceImg}
      />
      <div className="py-10 px-2 md:px-4 max-w-7xl mx-auto">
        <h1 className="text-2xl md:text-3xl mb-4 font-semibold font-montserrat">
          Income Source Certificate Builder
        </h1>

        {/* Personal Details Section */}
        <div className="">
          <h1 className="text-xl font-semibold font-montserrat">
            Personal Details
          </h1>
          <div className="p-4 pt-10 border bg-gray-100 rounded-md">
            <div className="grid font-afcacad text-sm md:text-lg gap-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
              {/* Gender */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Title<span className="text-red-500">*&nbsp;</span>
                </label>
                <div className="flex gap-3 items-center h-[45px]">
                  <input
                    type="radio"
                    name="gender"
                    value="mr"
                    checked={state.personalInfo.mr}
                    onChange={handleRadioChange}
                    className="mt-1 border rounded-md scale-150"
                  />
                  <strong className="font-medium">Mr.</strong>
                  <input
                    type="radio"
                    name="gender"
                    value="mrs"
                    checked={state.personalInfo.mrs}
                    onChange={handleRadioChange}
                    className="mt-1 border rounded-md scale-150"
                  />
                  <strong className="font-medium">Mrs.</strong>
                  <input
                    type="radio"
                    name="gender"
                    value="ms"
                    checked={state.personalInfo.ms}
                    onChange={handleRadioChange}
                    className="mt-1 border rounded-md scale-150"
                  />
                  <strong className="font-medium">Miss.</strong>
                </div>
              </div>

              {/* Name */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Full Name<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="name"
                  value={state.personalInfo.name}
                  onChange={handlePersonalInfoChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Ram Adhikari"
                />
              </div>

              {/* Father Name */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Father Name<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="fatherName"
                  value={state.personalInfo.fatherName}
                  onChange={handlePersonalInfoChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Gajendra Adhikari"
                />
              </div>

              {/* Mother Name */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Mother Name<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="motherName"
                  value={state.personalInfo.motherName}
                  onChange={handlePersonalInfoChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Sushila Adhikari"
                />
              </div>

              {/* Local Level Type */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Local Government Level<span className="text-red-500">*&nbsp;</span>
                </label>
                <div className="flex gap-2 items-center h-[45px]">
                  <input
                    type="radio"
                    name="localgovernmentlevel"
                    value="ruralmuncipality"
                    checked={state.personalInfo.ruralmuncipality}
                    onChange={handleLocalLevelRadioChange}
                    className="mt-1 border rounded-md scale-150"
                  />
                  <strong className="font-medium">Rural</strong>
                  <input
                    type="radio"
                    name="localgovernmentlevel"
                    value="municipality"
                    checked={state.personalInfo.municipality}
                    onChange={handleLocalLevelRadioChange}
                    className="mt-1 border rounded-md scale-150"
                  />
                  <strong className="font-medium">Muncipality</strong>
                  <input
                    type="radio"
                    name="localgovernmentlevel"
                    value="submetropolitan"
                    checked={state.personalInfo.submetropolitan}
                    onChange={handleLocalLevelRadioChange}
                    className="mt-1 border rounded-md scale-150"
                  />
                  <strong className="font-medium">Sub-Metro</strong>
                  <input
                    type="radio"
                    name="localgovernmentlevel"
                    value="metropolitan"
                    checked={state.personalInfo.metropolitan}
                    onChange={handleLocalLevelRadioChange}
                    className="mt-1 border rounded-md scale-150"
                  />
                  <strong className="font-medium">Metropolitan</strong>
                </div>
              </div>

              {/* Address Fields */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Local Body Name<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="localgovernment"
                  value={state.personalInfo.localgovernment}
                  onChange={handlePersonalInfoChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Kathmandu"
                />
              </div>

              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Ward No.<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="number"
                  name="wardNo"
                  value={state.personalInfo.wardNo}
                  onChange={handlePersonalInfoChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: 07"
                />
              </div>

              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  District<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="district"
                  value={state.personalInfo.district}
                  onChange={handlePersonalInfoChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Kathmandu"
                />
              </div>

              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Province<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="province"
                  value={state.personalInfo.province}
                  onChange={handlePersonalInfoChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Koshi"
                />
              </div>

              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Date<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="date"
                  value={state.personalInfo.date}
                  onChange={handlePersonalInfoChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: 20/01/2020"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Additional Details Section */}
        <div className="pt-10">
          <h1 className="text-xl font-semibold font-montserrat">
            Additional Details
          </h1>
          <div className="p-4 pt-10 border bg-gray-100 rounded-md">
            <div className="grid font-afcacad text-sm md:text-lg gap-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Ward Chairperson Name<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="wardChairPersonName"
                  value={state.personalInfo.wardChairPersonName}
                  onChange={handlePersonalInfoChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Ram Adhikari"
                />
              </div>

              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Ward Chairperson Email<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="email"
                  name="wardChairPersonEmail"
                  value={state.personalInfo.wardChairPersonEmail}
                  onChange={handlePersonalInfoChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: nawaraj@gmail.com"
                />
              </div>

              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Ward Chairperson Phone<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="tel"
                  name="wardChairPersonPhone"
                  value={state.personalInfo.wardChairPersonPhone}
                  onChange={handlePersonalInfoChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: 9842000000"
                />
              </div>

              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Fiscal Year<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="fiscalyear"
                  value={state.personalInfo.fiscalyear}
                  onChange={handlePersonalInfoChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: 2023/2024"
                />
              </div>

              <div className="mb-4 w-full">
                <label htmlFor="verification-currency" className="block text-sm md:text-lg font-medium">
                  Verification Currency<span className="text-red-500">*</span>
                </label>
                <select
                  id="verification-currency"
                  name="verificationCurrency"
                  value={verificationCurrency}
                  onChange={handleVerificationCurrencyChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  required
                >
                  <option value="">-- Select Verification Currency --</option>
                  {currencies.filter(c => c.code !== "NPR").map((currency) => (
                    <option key={currency.code} value={currency.code}>
                      {currency.code} - {currency.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Exchange Rate (1 {verificationCurrency || 'Foreign Currency'} = ? NPR)
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="number"
                  name="verificationRate"
                  value={verificationRate}
                  onChange={handleVerificationRateChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder={`Enter ${verificationCurrency || 'foreign currency'} rate`}
                  step="0.0001"
                  disabled={!verificationCurrency}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Income Details Section */}
        <div className="pt-10">
          <h1 className="text-xl font-semibold font-montserrat">
            Income Details
          </h1>
          <div className="p-4 pt-10 border bg-gray-100 rounded-md">
            {state.incomeSources.map((incomeInfo, i) => (
              <div
                className="grid font-afcacad text-sm md:text-lg gap-2 grid-cols-[3fr_3fr_3fr_2fr_2fr_2fr_1fr] place-items-center md:place-content-center overflow-x-scroll"
                key={i}
              >
                {/* Income Source */}
                <div className="mb-4 w-[200px] md:w-full">
                  <label className="block text-sm md:text-lg font-medium">
                    Income Source<span className="text-red-500">*&nbsp;</span>
                  </label>
                  <input
                    type="text"
                    name="incomeSource"
                    value={incomeInfo.incomeSource}
                    onChange={(e) => handleIncomeChange(e, i)}
                    className="w-full mt-1 p-2 border rounded-md"
                    placeholder="Eg: Salary"
                  />
                </div>

                {/* Owner */}
                <div className="mb-4 w-[200px] md:w-full">
                  <label className="block text-sm md:text-lg font-medium">
                    Income Holder Name<span className="text-red-500">*&nbsp;</span>
                  </label>
                  <input
                    type="text"
                    name="owner"
                    value={incomeInfo.owner}
                    onChange={(e) => handleIncomeChange(e, i)}
                    className="w-full mt-1 p-2 border rounded-md"
                    placeholder="Eg: Ram Adhikari"
                  />
                </div>

                {/* Gender */}
                <div className="mb-4 w-[200px] md:w-full">
                  <label className="block text-sm md:text-lg font-medium">
                    Choose Option<span className="text-red-500">*&nbsp;</span>
                  </label>
                  <div className="flex gap-4 items-center h-[45px]">
                    <input
                      type="radio"
                      name={`gender${i}`}
                      value="mr"
                      checked={incomeInfo.mr}
                      onChange={(e) => handleRadioChangeForIncome(e, i)}
                      className="mt-1 border rounded-md scale-150"
                    />
                    <strong className="font-medium">Mr.</strong>
                    <input
                      type="radio"
                      name={`gender${i}`}
                      value="mrs"
                      checked={incomeInfo.mrs}
                      onChange={(e) => handleRadioChangeForIncome(e, i)}
                      className="mt-1 border rounded-md scale-150"
                    />
                    <strong className="font-medium">Mrs.</strong>
                    <input
                      type="radio"
                      name={`gender${i}`}
                      value="ms"
                      checked={incomeInfo.ms}
                      onChange={(e) => handleRadioChangeForIncome(e, i)}
                      className="mt-1 border rounded-md scale-150"
                    />
                    <strong className="font-medium">Miss.</strong>
                  </div>
                </div>

                {/* Annual Income */}
                <div className="mb-4 w-[200px] md:w-full">
                  <label className="block text-sm md:text-lg font-medium">
                    Annual Income<span className="text-red-500">*&nbsp;</span>
                  </label>
                  <input
                    type="number"
                    name="annualIncome"
                    value={incomeInfo.annualIncome}
                    onChange={(e) => handleIncomeChange(e, i)}
                    className="w-full mt-1 p-2 border rounded-md"
                    placeholder="Eg: 50000"
                  />
                </div>

                {/* Currency */}
                <div className="mb-4 w-[200px] md:w-full">
                  <label className="block text-sm md:text-lg font-medium">
                    Currency<span className="text-red-500">*&nbsp;</span>
                  </label>
                  <select
                    name="currency"
                    value={incomeInfo.currency}
                    onChange={(e) => handleIncomeChange(e, i)}
                    className="w-full mt-1 p-2 border rounded-md"
                  >
                    {currencies.map((currency) => (
                      <option key={currency.code} value={currency.code}>
                        {currency.code} - {currency.name}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Remarks */}
                <div className="mb-4 w-[200px] md:w-full">
                  <label className="block text-sm md:text-lg font-medium">
                    Remarks
                  </label>
                  <input
                    type="text"
                    name="remarks"
                    value={incomeInfo.remarks}
                    onChange={(e) => handleIncomeChange(e, i)}
                    className="w-full mt-1 p-2 border rounded-md"
                    placeholder="Eg: last year"
                  />
                </div>

                <div className="mt-0 md:mt-5 self-center flex items-center gap-2 place-self-end">
                  <ImCross
                    className="cursor-pointer"
                    color="red"
                    size={20}
                    onClick={() => {
                      if (state.incomeSources.length > 1) {
                        handleDeleteIncomeSource(i);
                      }
                    }}
                    title="Delete"
                  />
                  <FaSquarePlus
                    className="cursor-pointer"
                    color="#01132b"
                    size={30}
                    onClick={handleAddIncomeSource}
                    title="Add"
                  />
                </div>
              </div>
            ))}

            {/* Exchange Rates Section */}
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 mt-6">
              {Object.entries(exchangeRates)
                .filter(([currency]) =>
                  currency === "NPR" ||
                  state.incomeSources.some(info => info.currency === currency)
                )
                .map(([currency, rate]) => (
                  <div key={currency}>
                    <label className="block font-medium mb-1">
                      {currency} ({getCurrencySymbol(currency)}) Rate
                    </label>
                    <input
                      type="number"
                      value={rate}
                      onChange={(e) => handleExchangeRateChange(currency, e.target.value)}
                      className="w-full p-2 border rounded disabled:opacity-70 disabled:cursor-not-allowed"
                      placeholder={`Enter ${currency} rate`}
                      step="0.0001"
                      disabled={currency === "NPR"}
                    />
                  </div>
                ))}
            </div>

            <div className="mt-4">
              You can find today's conversion rate as per Nepal Rastra Bank at {""}
              <a
                href="https://www.nrb.org.np/forex/"
                passHref
                target="_blank"
                rel="noopener noreferrer"
                className="underline"
              >
                <span>NRB Forex Rates</span>
              </a>
              .
            </div>
          </div>
        </div>

        {/* Document Preview */}
        <div className="p-4 border bg-white rounded-md">
          <h2 className="text-2xl font-semibold mb-10">Document Preview</h2>
          <div
            ref={previewRef}
            className="p-4 A4-size font-montserrat overflow-hidden"
          >
            <div className="h-[200px] w-full"></div>
            <p className="text-end font-bold">
              Date: {state.personalInfo.date || "............................."}
            </p>
            <div className="text-center pt-10">
              <p className="uppercase text-xl font-bold underline">
                Subject: Verification of Annual Income
              </p>
              <p className="text-xl font-bold underline">
                To Whom it May Concern
              </p>
            </div>
            <div className="pt-10 text-s text-justify">
              This is to certify that &nbsp;
              <strong>
                {state.personalInfo.fatherName ? "Mr. " + state.personalInfo.fatherName : "............. "}
              </strong>
              {" "} (Father) and {" "}
              <strong>
                {" "}
                {state.personalInfo.motherName ? "Mrs. " + state.personalInfo.motherName : ".............."}
              </strong>
              {" "} (Mother) of {" "}
              <strong>
                {state.personalInfo.mr ? "Mr." : state.personalInfo.mrs ? "Mrs." : state.personalInfo.ms ? "Miss" : "Mr/Mrs."}
                {" "}
                {state.personalInfo.name ? state.personalInfo.name : ".............."}
              </strong>
              , a permanent residents of
              <strong>
                {" "}
                {state.personalInfo.localgovernment || ".............."}{" "}
                {" "}
                {state.personalInfo.ruralmuncipality ? "Rural Muncipality" :
                  state.personalInfo.municipality ? "Muncipality" :
                    state.personalInfo.submetropolitan ? "Sub-Metropolitan City" :
                      state.personalInfo.metropolitan ? "Metropolitan City" :
                        "Rural Muncipality/Muncipality/Sub Metropolitan/Metropolitan"}
                , Ward No.
                {state.personalInfo.wardNo ? state.personalInfo.wardNo + "" : "........."},
                {state.personalInfo.district ? " " + state.personalInfo.district : "............"}{" "}
                District,
              </strong>
              <strong> {state.personalInfo.province ? " " + state.personalInfo.province : "................."}{" "} Province, Nepal</strong> {" "}
              have submitted an application for verification of their annual income in {verificationCurrency || "foreign currency"}, It is hereby certified that they have the following income for <strong>Fiscal Year {state.personalInfo.fiscalyear || "............"}</strong> from following sources as listed below:
            </div>

            {/* Income Table */}
            <div className="pt-5 text-xs">
              <table className="table-auto border-collapse border border-gray-300 w-full">
                <thead>
                  <tr className="text-xs text-center font-bold">
                    <td className="border border-gray-300 px-2 py-2">S.N.</td>
                    <td className="border border-gray-300 px-2 py-2">Income Source</td>
                    <td className="border border-gray-300 px-2 py-2">Income Holder</td>
                    <td className="border border-gray-300 px-2 py-2">
                      {`Fiscal Year  ${state.personalInfo.fiscalyear}`}
                    </td>
                    <td className="border border-gray-300 px-2 py-2">Currency</td>
                    <td className="border border-gray-300 px-2 py-2">Remarks</td>
                  </tr>
                </thead>
                <tbody>
                  {state.incomeSources.map((info, i) => (
                    <tr key={i}>
                      <td className="border border-gray-300 px-4 py-2">{i + 1}</td>
                      <td className="border border-gray-300 px-4 py-2">
                        {info.incomeSource || "......."}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {info.owner
                          ? `${info.mr ? "Mr. " : info.mrs ? "Mrs. " : "Miss "}${info.owner}`
                          : "Mr./Mrs./Miss"}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        NPR {((info.annualIncome || 0) * (exchangeRates[info.currency] || 0)).toLocaleString()} /-
                        <br />
                        ({getCurrencySymbol(info.currency)} {info.annualIncome || ""} {getCurrencyName(info.currency)} )
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {info.currency}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {info.remarks || ""}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* Conversion Summary */}
              <div className="mt-6 p-4 border rounded-md">
                <table className="w-full">
                  <tbody>
                    {/* NPR income */}
                    {totals.NPR > 0 && (
                      <tr>
                        <td className="font-semibold py-2">
                          Total income in NPR:
                        </td>
                        <td className="text-right py-2">
                          NPR {totals.NPR.toLocaleString()}
                        </td>
                      </tr>
                    )}

                    {/* Verification Currency Summary */}
                    {verificationCurrency && verificationRate && (
                      <>
                        <tr>
                          <td className="font-semibold py-2">
                            Total income in {verificationCurrency}:
                            <br />
                            (Conversion rate: 1 {verificationCurrency} = NPR {verificationRate})
                          </td>
                          <td className="text-right py-2">
                            {getCurrencySymbol(verificationCurrency)} {verificationTotal.toLocaleString()}
                          </td>
                        </tr>

                      </>
                    )}

                    {/* Grand Total */}
                    <tr className="border-t border-gray-300">
                      <td className="font-bold py-2">GRAND TOTAL:</td>
                      <td className="text-right font-bold py-2">
                        NPR {totals.NPR.toLocaleString()}
                        {verificationCurrency && verificationRate && (
                          <div className="text-sm">
                            ({getCurrencySymbol(verificationCurrency)} {verificationTotal.toLocaleString()} {verificationCurrency})
                          </div>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
                {/* Exchange Rate Statement */}
                <div className="mt-6 text-sm">
                  <p>
                    Today's foreign exchange rate is 1 {verificationCurrency || 'Foreign Currency'} = {verificationRate || '___'} NPR
                    {Object.entries(exchangeRates)
                      .filter(([currency]) =>
                        currency !== "NPR" &&
                        currency !== verificationCurrency &&
                        state.incomeSources.some(info => info.currency === currency)
                      )
                      .map(([currency, rate], index, array) => (
                        <span key={currency}>
                          {index === array.length - 1 && array.length > 0 ? ' and ' : ', '}
                          1 {currency} = {rate || '___'} NPR
                        </span>
                      ))
                    } according to selling rate Nepal Rastra Bank.
                  </p>
                </div>
              </div>
            </div>

            {/* Signature Section */}
            <div className="pt-20">
              <div className="flex flex-col items-end">
                <div className="w-48 h-0.5 my-2 bg-black"></div>
                <strong>
                  {state.personalInfo.wardChairPersonName ||
                    "..........................................................."}
                </strong>
                <p className="font-bold">Ward Chairperson</p>
                <p>
                  {state.personalInfo.wardChairPersonPhone ||
                    "..........................................................."}
                </p>
                <p>
                  {state.personalInfo.wardChairPersonEmail ||
                    "..........................................................."}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Download Button */}
        <button
          onClick={handlePrint}
          className="px-4 mt-2 py-2 bg-[#eb4934] hover:bg-[#d43b2e] text-white rounded-md cursor-pointer"
        >
          Download PDF
        </button>
      </div>
    </>
  );
};

export default IncomeSourceCertificate;