import React, { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import NavigationBreadcrumb from "./utils/NavigationBreadcrumb";
import SEO from "../../../../components/SEO";
import taxClearance from "../../../../images/tax-clearance.png";


const TaxClearanceCertificate = () => {
  const previewRef = useRef();

  const [information, setInformation] = useState({
    sponserName: "",
    smr: "",
    smrs: "",
    sms: "",
    applicantName: "",
    mr: false,
    mrs: false,
    ms: false,
    municipality: "",
    wardNo: "",
    location: "",
    district: "",
    date: "",
    fiscalYear: "",
    positionOfApprovingPerson: "",
    approvingPersonEmail: "",
    approvingPersonPhone: ""
  });


  const handleRadioChange = (e) => {
    const { value } = e.target;
    setInformation({
      ...information,
      mr: value === "mr",
      mrs: value === "mrs",
      ms: value === "ms",
    });
  };

  const handleRadioChangeForSponsor = (e) => {
    const { value } = e.target;

    setInformation({
      ...information,
      smr: value === "smr",
      smrs: value === "smrs",
      sms: value === "sms",
    });
  };

  const handleChange = (e) => {
    setInformation({ ...information, [e.target.name]: e.target.value });
  };

  const handlePrint = useReactToPrint({
    content: () => previewRef.current,
    documentTitle: "tax_clearance_letter",
    pageStyle: `
            @page { 
                size: A4; 
                margin: 40px;  /* Set consistent margin for print */
            }
            body { 
                margin: 40px;  /* Set consistent margin for the body */
            }
        `,
  });

  return (
    <>

      <NavigationBreadcrumb slug={"tax-clearance"} name={"Tax Clearance"} />
      <SEO
        title={"Tax Clearance Certificate"}
        description={
          "Build your documents, and manage your applications with ease."
        }
        name={"Tax Clearance Certificate"}
        type={"website"}
        image={taxClearance}
        path={"/tools/documents/tax-clearance"}
      />
      <div className="py-10 px-2 md:px-4 max-w-7xl mx-auto">
        <div>
          <h1 className="text-2xl md:text-3xl mb-4 font-semibold font-montserrat">
            Tax Clearance Certificate{" "}
          </h1>

          <div className="p-4 pt-10 border bg-gray-100 rounded-md">
            <div className="grid font-afcacad text-sm md:text-lg gap-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
              {/* Father Name  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Sponser Name<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="sponserName"
                  value={information.sponserName}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Gajendra Bahadur Basnet"
                  aria-label="Sponser Name input"
                  autoComplete="off"
                />
              </div>

              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Choose Option<span className="text-red-500">*&nbsp;</span>
                </label>
                <div className="flex gap-4 items-center h-[45px]">
                  <input
                    type="radio"
                    name="sgender"
                    value="smr"
                    checked={information.smr}
                    onChange={handleRadioChangeForSponsor}
                    className="mt-1  border rounded-md scale-150"
                    aria-label="Gender input"
                    autoComplete="off"
                  />{" "}
                  <strong className="font-medium">Mr.</strong>
                  <input
                    type="radio"
                    name="sgender"
                    value="smrs"
                    checked={information.smrs}
                    onChange={handleRadioChangeForSponsor}
                    className="mt-1 border rounded-md scale-150"
                    aria-label="Gender input"
                    autoComplete="off"
                  />{" "}
                  <strong className="font-medium">Mrs.</strong>
                  <input
                    type="radio"
                    name="sgender"
                    value="sms"
                    checked={information.sms}
                    onChange={handleRadioChangeForSponsor}
                    className="mt-1 border rounded-md scale-150"
                    aria-label="Gender input"
                    autoComplete="off"
                  />
                  <strong className="font-medium">Miss.</strong>
                </div>
              </div>
              {/* Applicant Name  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Applicant Name<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="applicantName"
                  value={information.applicantName}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Yubraj Basnet"
                  aria-label="applicant name input"
                  autoComplete="off"
                />
              </div>

              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Choose Option<span className="text-red-500">*&nbsp;</span>
                </label>
                <div className="flex gap-4 items-center h-[45px]">
                  <input
                    type="radio"
                    name="gender"
                    value="mr"
                    checked={information.mr}
                    onChange={handleRadioChange}
                    className="mt-1  border rounded-md scale-150"
                    aria-label="Gender input"
                    autoComplete="off"
                  />{" "}
                  <strong className="font-medium">Mr.</strong>
                  <input
                    type="radio"
                    name="gender"
                    value="mrs"
                    checked={information.mrs}
                    onChange={handleRadioChange}
                    className="mt-1 border rounded-md scale-150"
                    aria-label="Gender input"
                    autoComplete="off"
                  />{" "}
                  <strong className="font-medium">Mrs.</strong>
                  <input
                    type="radio"
                    name="gender"
                    value="ms"
                    checked={information.ms}
                    onChange={handleRadioChange}
                    className="mt-1 border rounded-md scale-150"
                    aria-label="Gender input"
                    autoComplete="off"
                  />
                  <strong className="font-medium">Miss.</strong>
                </div>
              </div>
              {/* Municipality Name  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Municipality<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="municipality"
                  value={information.municipality}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Budhanilkantha"
                  aria-label="Municipality input"
                  autoComplete="off"
                />
              </div>
              {/* Ward No  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Ward No.<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="wardNo"
                  value={information.wardNo}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: 02"
                  aria-label="Ward No input"
                  autoComplete="off"
                />
              </div>
              {/* Location */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Location<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="location"
                  value={information.location}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Golphutar"
                  aria-label="Location input"
                  autoComplete="off"
                />
              </div>
              {/* District  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  District<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="district"
                  value={information.district}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Kathmandu"
                  aria-label="District input"
                  autoComplete="off"
                />
              </div>
              {/* Date  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Date<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="date"
                  value={information.date}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: 2014/11/18"
                  aria-label="Date input"
                  autoComplete="off"
                />
              </div>
              {/* Fiscal Year  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Fiscal Year<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="fiscalYear"
                  value={information.fiscalYear}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: 2014/2015"
                  aria-label="Fiscal Year input"
                  autoComplete="off"
                />
              </div>


              {/* Approved By  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Approved By<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="approvedBy"
                  value={information.approvedBy}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Raju Prasad Neupane"
                  aria-label="Approved By input"
                  autoComplete="off"
                />
              </div>
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Approving Person Email<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="approvingPersonEmail"
                  value={information.approvingPersonEmail}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: nawaraj@gmail.com"
                  aria-label="Approved By Input"
                  autoComplete="off"
                />
              </div>
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Approving Person Phone<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="approvingPersonPhone"
                  value={information.approvingPersonPhone}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: 9800000000"
                  aria-label="Approved By input"
                  autoComplete="off"
                />
              </div>
              {/* Position of Approving Person  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Position of Approving Person
                  <span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="positionOfApprovingPerson"
                  value={information.positionOfApprovingPerson}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Act. Executive Officer"
                  aria-label="Position of Approving Person input"
                  autoComplete="off"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white rounded-md">
          <h2 className="text-2xl mt-5 font-semibold mb-10">
            Document Preview
          </h2>
          <div ref={previewRef} className={`p-4 font-montserrat A4-size`}>
            <div className="h-[200px] w-full"></div>
            <p className="text-end font-bold">
              Date:{" "}
              {information.date
                ? information.date
                : "............................."}
            </p>
            <div className="text-center pt-20">

              <p className="text-xl font-bold">Subject: Tax Clearance Certificate</p>
              <p className="mt-5 text-2xl font-bold underline">
                To Whom it May Concern
              </p>
            </div>
            <div className="pt-10 text-justify">

              This is to certify that
              <strong>
                {" "}
                {
                  information.smr ? "Mr." : information.smrs ? "Mrs." : information.sms ? "Miss" : "Mr./Mrs./Miss."
                }
                {" "}
                {information.sponserName ? information.sponserName : "..............................."}
              </strong>
              {" "}
              of
              {" "}
              <strong>
                {information.mr ? "Mr." : information.mrs ? "Mrs." : information.ms ? "Miss" : "Mr./Mrs./Miss."}
                {" "}
                {information.applicantName ? information.applicantName : "..............................."}
              </strong>
              , permanent resident of  <strong>
                {information.municipality &&
                  information.wardNo &&
                  information.location &&
                  information.district
                  ? information.municipality +
                  " " +
                  information.wardNo +
                  " " +
                  information.location +
                  " " +
                  information.district +
                  ", Nepal"
                  : "............."}
              </strong> have paid all the taxes including property taxes and other income tax in this Municipality up to this fiscal year <strong>
                {
                  information.fiscalYear ? information.fiscalYear + "." : ".........................."
                }
              </strong>


            </div>

            <div className="pt-32">
              <div className=" flex flex-col items-end">
                <div className="w-48 h-0.5 my-2 bg-black"></div>


                <p className="font-bold">
                  {information.approvedBy
                    ? information.approvedBy
                    : "............................................."}
                </p>
                <p className="text-[16px] font-bold">
                  {information.positionOfApprovingPerson
                    ? information.positionOfApprovingPerson
                    : "............................................."}
                </p>
                <p>
                  {information.approvingPersonEmail
                    ? information.approvingPersonEmail
                    : "..........................................................."}
                </p>
                <p>
                  {information.approvingPersonPhone
                    ? information.approvingPersonPhone
                    : "..........................................................."}
                </p>
              </div>
            </div>
          </div>
        </div>
        <button
          onClick={() => {
            handlePrint();
          }}
          className={`px-4 mt-2 py-2 bg-[#eb4934] hover:bg-[#d43b2e] text-white rounded-md cursor-pointer`}
        >
          Download PDF
        </button>
      </div>
    </>
  );
};

export default TaxClearanceCertificate;
