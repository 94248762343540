import React, { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import NavigationBreadcrumb from "./utils/NavigationBreadcrumb";
import SEO from "../../../../components/SEO";
import birthImage from "../../../../images/birth-certificate.png";


const BirthCertificate = () => {
  const previewRef = useRef();
  const [information, setInformation] = useState({
    name: "",
    wardChairPersonName: "",
    wardChairPersonEmail: "",
    wardChairPersonPhone: "",
    mr: false,
    mrs: false,
    ms: false,
    fatherName: "",
    motherName: "",
    metroPolitan: false,
    subMetroPolitan: false,
    municipality: false,
    ruralRuralMunicipality: false,
    localBody: "",
    district: "",
    wardNo: "",
    province: "",
    creationDate: "",
    birthDateAD: "",
    birthDateBS: "",
  });

  const handleChange = (e) => {
    setInformation({ ...information, [e.target.name]: e.target.value });
  };

  const handleRadioChangeForGender = (e) => {
    const { value } = e.target;
    setInformation({
      ...information,
      mr: value === "mr",
      mrs: value === "mrs",
      ms: value === "ms",
    });
  };

  const handleRadioChangeForLocalBody = (e) => {
    const { value } = e.target;

    setInformation({
      ...information,
      metroPolitan: value === "metroPolitan", // Set to true if selected
      subMetroPolitan: value === "subMetroPolitan", // Set to true if selected
      municipality: value === "municipality", // Set to true if selected
      ruralRuralMunicipality: value === "ruralRuralMunicipality", // Set to true if selected
    });
  };

  const handlePrint = useReactToPrint({
    content: () => previewRef.current,
    documentTitle: "birth_certificate",
    pageStyle: `
            @page { 
                size: A4; 
                margin: 40px;  /* Set consistent margin for print */
            }
            body { 
                margin: 40px;  /* Set consistent margin for the body */
            }
        `,
  });

  return (
    <>
      <NavigationBreadcrumb
        slug={"birth-certificate"}
        name={"Birth Certificate"}
      />
      <SEO
        title={"Birth Certificate"}
        description={
          "Build your documents, and manage your applications with ease."
        }
        name={"Birth Certificate"}
        type={"website"}
        image={birthImage}
        path={"/tools/documents/birth-certificate"}
      />
      <div className="py-10 px-2 md:px-4 max-w-7xl mx-auto">
        <div>
          <h1 className="text-2xl md:text-3xl mb-4 font-semibold font-montserrat">
            Birth Certificate{" "}
          </h1>

          <div className="p-4 pt-10 border bg-gray-100 rounded-md">
            <div className="grid font-afcacad gap-2 grid-cols-1 md:grid-cols-3">
              {/*  Name  */}
              <div className="mb-4 w-full">
                <label className="block text-lg font-medium">
                  Name<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="name"
                  value={information.name}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Hemanta Basnet"
                  aria-label="Name input"
                  autoComplete="off"
                />
              </div>
              {/*  choose option  */}
              <div className="mb-4 w-full">
                <label className="block text-lg font-medium">
                  Choose Option<span className="text-red-500">*&nbsp;</span>
                </label>
                <div className="flex gap-4 items-center h-[45px]">
                  <input
                    type="radio"
                    name="gender"
                    value="mr"
                    checked={information.mr}
                    onChange={handleRadioChangeForGender}
                    className="mt-1  border rounded-md scale-150"
                    aria-label="Gender input"
                    autoComplete="off"
                  />{" "}
                  <strong className="font-medium text-lg">Mr.</strong>
                  <input
                    type="radio"
                    name="gender"
                    value="mrs"
                    checked={information.mrs}
                    onChange={handleRadioChangeForGender}
                    className="mt-1 border rounded-md scale-150"
                    aria-label="Gender input"
                    autoComplete="off"
                  />{" "}
                  <strong className="font-medium text-lg">Mrs.</strong>
                  <input
                    type="radio"
                    name="gender"
                    value="ms"
                    checked={information.ms}
                    onChange={handleRadioChangeForGender}
                    className="mt-1 border rounded-md scale-150"
                    aria-label="Gender input"
                    autoComplete="off"
                  />
                  <strong className="font-medium text-lg">Miss.</strong>
                </div>
              </div>
              {/* Birth Date A.D  */}
              <div className="mb-4 w-full">
                <label className="block text-lg font-medium">
                  Birth Date (A.D.)<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="birthDateAD"
                  value={information.birthDateAD}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: 1997-11-14"
                  aria-label="Birth Date (A.D.) input"
                  autoComplete="off"
                />
              </div>
              {/* Birth date BS  */}
              <div className="mb-4 w-full">
                <label className="block text-lg font-medium">
                  Birth Date (B.S.)<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="birthDateBS"
                  value={information.birthDateBS}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: 2054/07/29"
                  aria-label="Birth Date (B.S.) input"
                  autoComplete="off"
                />
              </div>
              {/* Father Name  */}
              <div className="mb-4 w-full">
                <label className="block text-lg font-medium">
                  Father Name<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="fatherName"
                  value={information.fatherName}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Yubraj Basnet"
                  aria-label="Father Name input"
                  autoComplete="off"
                />
              </div>
              {/* Mother Name  */}
              <div className="mb-4 w-full">
                <label className="block text-lg font-medium">
                  Mother Name<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="motherName"
                  value={information.motherName}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Kaushila Basnet"
                  aria-label="Mother Name input"
                  autoComplete="off"
                />
              </div>
              {/* Local Body Name  */}
              <div className="mb-4 w-full">
                <label className="block text-lg font-medium">
                  Local Body<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="localBody"
                  value={information.localBody}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Itahari"
                  aria-label="Local Body Name input"
                  autoComplete="off"
                />
              </div>
              <div className="mb-4 w-full">
                <label className="block text-lg font-medium">
                  Choose Option<span className="text-red-500">*&nbsp;</span>
                </label>
                <div className="grid grid-cols-2 items-center h-[45px]">
                  <div className="flex gap-2">
                    <input
                      type="radio"
                      name="localBody"
                      value="metroPolitan"
                      checked={information.metroPolitan}
                      onChange={handleRadioChangeForLocalBody}
                      className="mt-1  border rounded-md scale-125"
                      aria-label="Local Body choose"
                      autoComplete="off"
                    />
                    <strong className="font-medium text-sm">
                      MetroPolitian
                    </strong>
                  </div>
                  <div className="flex gap-2">
                    <input
                      type="radio"
                      name="localBody"
                      value="subMetroPolitan"
                      checked={information.subMetroPolitan}
                      onChange={handleRadioChangeForLocalBody}
                      className="mt-1 border rounded-md scale-125"
                      aria-label="Local Body choose"
                      autoComplete="off"
                    />
                    <strong className="font-medium text-sm">
                      Sub-Metropolitan
                    </strong>
                  </div>
                  <div className="flex gap-2">
                    <input
                      type="radio"
                      name="localBody"
                      value="municipality"
                      checked={information.municipality}
                      onChange={handleRadioChangeForLocalBody}
                      className="mt-1 border rounded-md scale-125"
                      aria-label="Local Body choose"
                      autoComplete="off"
                    />
                    <strong className="font-medium text-sm">
                      Municipality
                    </strong>
                  </div>
                  <div className="flex gap-2">
                    <input
                      type="radio"
                      name="localBody"
                      value="ruralRuralMunicipality"
                      checked={information.ruralRuralMunicipality}
                      onChange={handleRadioChangeForLocalBody}
                      className="mt-1 border rounded-md scale-125"
                      aria-label="Local Body choose"
                      autoComplete="off"
                    />
                    <strong className="font-medium text-sm">
                      Rural Municipality
                    </strong>
                  </div>
                </div>
              </div>
              {/* District  */}
              <div className="mb-4 w-full">
                <label className="block text-lg font-medium">
                  District<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="district"
                  value={information.district}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Morang"
                  aria-label="District input"
                  autoComplete="off"
                />
              </div>
              {/* Ward No  */}
              <div className="mb-4 w-full">
                <label className="block text-lg font-medium">
                  Ward No.<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="wardNo"
                  value={information.wardNo}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: 02"
                  aria-label="Ward No input"
                  autoComplete="off"
                />
              </div>
              {/* Province */}
              <div className="mb-4 w-full">
                <label className="block text-lg font-medium">
                  Province<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="province"
                  value={information.province}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Koshi"
                  aria-label="Province input"
                  autoComplete="off"
                />
              </div>
              {/* Creation Date  */}
              <div className="mb-4 w-full">
                <label className="block text-lg font-medium">
                  Creation Date<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="creationDate"
                  value={information.creationDate}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: 20/01/2020"
                  aria-label="Creation Date input"
                  autoComplete="off"
                />
              </div>
              {/* ward chairperson name  */}
              <div className="mb-4 w-full">
                <label className="block text-lg font-medium">
                  Ward Chairperson Name
                  <span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="wardChairPersonName"
                  onChange={handleChange}
                  value={information.wardChairPersonName}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Nawaraj Sapkota"
                  aria-label="Ward Chairperson Name input"
                  autoComplete="off"
                />
              </div>
              {/* ward chairperson email  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Ward Chairperson Email
                  <span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="email"
                  name="wardChairPersonEmail"
                  value={information.wardChairPersonEmail}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: nawaraj@gmail.com"
                  aria-label="Ward Chairperson Email input"
                  autoComplete="off"
                />
              </div>
              {/* ward chairperson Phone  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Ward Chairperson Phone
                  <span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="wardChairPersonPhone"
                  value={information.wardChairPersonPhone}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: 9842000000"
                  aria-label="Ward Chairperson Phone input"
                  autoComplete="off"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="p-4 border bg-white rounded-md">
          <h2 className="text-2xl font-semibold mb-10">Document Preview</h2>
          <div ref={previewRef} className="p-4 A4-size font-montserrat">
            <div className="h-[200px] w-full"></div>
            <strong className="flex justify-end">
              Date:{" "}
              {information.creationDate
                ? information.creationDate
                : "..................................."}
            </strong>
            <div className="text-center pt-10">
              <p className="text-xl font-bold uppercase">
                Subject: <span className="underline uppercase">Birth Certificate</span>
              </p>
              <p className="mt-5 text-2xl font-bold underline">
                To Whom it May Concern
              </p>
            </div>
            <div className="pt-10 text-justify">
              This is to certify that&nbsp;
              <strong>

                {information.name &&
                  (information.mr || information.mrs || information.ms)
                  ? information.mr
                    ? `Mr. ${information.name}`
                    : information.mrs
                      ? `Mrs. ${information.name}`
                      : `Ms. ${information.name}`
                  : "Mr./Mrs./Miss................"}
              </strong>

              , {
                information.mr ? "son" : (information.mrs || information.ms) ? "daughter" : "son/daughter"
              } of
              {" "}
              <strong>

                Mr.{" "}
                {information.fatherName
                  ? information.fatherName
                  : ".............."}{" "}
              </strong>

              and
              {" "}
              <strong>
                Mrs{" "}
                {information.motherName
                  ? information.motherName
                  : "................"}{" "}
              </strong>

              is the permanent residence of{" "}
              <strong>

                {information.localBody &&
                  (information.metroPolitan ||
                    information.subMetroPolitan ||
                    information.municipality ||
                    information.ruralRuralMunicipality)
                  ? information.metroPolitan
                    ? `${information.localBody} MetroPolitan City`
                    : information.subMetroPolitan
                      ? `${information.localBody} Sub-MetroPolitan City`
                      : information.municipality
                        ? `${information.localBody} Municipality`
                        : `${information.localBody} Rural Municipality`
                  : "........................"}{" "}
                , Ward no- {information.wardNo ? information.wardNo : "......"},{" "}
                {information.district ? information.district : ".........."}{" "}
                District,{" "}
                {information.province ? information.province : "..............."}{" "}
                Province, Nepal.
              </strong>
              {" "}
              According to
              {" "}
              {
                information.mr ? "his" : information.mrs || information.ms ? "her" : "his/her"
              } citizenship certificate date of
              birth of{" "}
              {information.name &&
                (information.mr || information.mrs || information.ms)
                ? information.mr
                  ? `Mr. ${information.name}`
                  : information.mrs
                    ? `Mrs. ${information.name}`
                    : `Ms. ${information.name}`
                : "Mr./Mrs./Miss................"}{" "}
              is{" "}
              {information.birthDateAD ? information.birthDateAD : ".........."}{" "}
              A.D. (
              {information.birthDateBS
                ? information.birthDateBS
                : "............."}{" "}
              B.S.)
            </div>

            <div className="flex justify-center text-center pt-10">
              <div className="mt-4 flex flex-col items-center justify-center">
                <div className="size-40 overflow-hidden border-black border-2 flex items-center justify-center">
                  <p className="-rotate-45 text-2xl">PHOTO</p>
                </div>
                <div className=" mt-4">
                  <strong className="text-xl">
                    {information.name &&
                      (information.mr || information.mrs || information.ms)
                      ? information.mr
                        ? `Mr. ${information.name}`
                        : information.mrs
                          ? `Mrs. ${information.name}`
                          : `Ms. ${information.name}`
                      : "........................."}
                  </strong>
                  <p className="font-bold">Applicant</p>
                </div>
              </div>
            </div>

            <div className="pt-32">
              <div className=" flex flex-col items-end">
                <div className="w-48 h-0.5 my-2 bg-black"></div>

                <strong>
                  {information.wardChairPersonName
                    ? information.wardChairPersonName
                    : "..........................................................."}
                </strong>
                <p className="font-bold">Ward Chairperson</p>
                <p>
                  {information.wardChairPersonPhone
                    ? information.wardChairPersonPhone
                    : "..........................................................."}
                </p>
                <p>
                  {information.wardChairPersonEmail
                    ? information.wardChairPersonEmail
                    : "..........................................................."}
                </p>
              </div>
            </div>
          </div>
        </div>
        <button
          onClick={() => {
            handlePrint();
          }}
          className={`px-4 mt-2 py-2 bg-[#eb4934] hover:bg-[#d43b2e] text-white rounded-md cursor-pointer`}
        // disabled={!checkFields}
        >
          Download PDF
        </button>
      </div>
    </>
  );
};

export default BirthCertificate;
