import React, { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import NavigationBreadcrumb from "./utils/NavigationBreadcrumb";
import SEO from "../../../../components/SEO";
import nameImage from "../../../../images/name-verification.png";


const NameVerification = () => {
  const previewRef = useRef();
  const [information, setInformation] = useState({
    firstName: {
      name: "",
      gender: "",
    },
    secondName: {
      name: "",
      gender: "",
    },
    municipality: "",
    wardNo: "",
    district: "",
    province: "",
    citizenshipNumber: "",

    surNames: [],
    nameOfWardChairPerson: "",
    wardChairPersonPhone: "",
    wardChairPersonEmail: "",
    date: "",
  });
  const [surName, setSurName] = useState("");
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Split the name to handle nested fields (e.g. "firstName.gender")
    const nameParts = name.split(".");

    // Function to update nested state
    const updateNestedState = (state, nameParts) => {
      if (nameParts.length === 1) {
        // Update a simple field
        return { ...state, [nameParts[0]]: value };
      }

      // If it's a nested field (e.g. "firstName.gender")
      const [firstPart, ...restParts] = nameParts;
      return {
        ...state,
        [firstPart]: updateNestedState(state[firstPart], restParts),
      };
    };

    // Update state using the helper function
    setInformation((prevState) => updateNestedState(prevState, nameParts));
  };

  const handleAddSurName = () => {
    if (surName && surName.trim() !== "") {
      setInformation({
        ...information,
        surNames: [...information.surNames, surName],
      });
      setSurName("");
      setError("");
    } else {
      setError("Null value is not accepted.");
    }
  };

  const handlePrint = useReactToPrint({
    content: () => previewRef.current,
    documentTitle: "tax_clearance_letter",
    pageStyle: `
            @page { 
                size: A4; 
                margin: 40px;  /* Set consistent margin for print */
            }
            body { 
                margin: 40px;  /* Set consistent margin for the body */
            }
        `,
  });

  return (
    <>

      <NavigationBreadcrumb
        slug={"name-verification"}
        name={"Name Verification"}
      />
      <SEO
        title={"Name Verification"}
        description={
          "Build your documents, and manage your applications with ease."
        }
        name={"Name Verification"}
        type={"website"}
        image={nameImage}
        path={"/tools/documents/name-verification"}
      />
      <div className="py-10 px-2 md:px-4 max-w-7xl mx-auto">
        <div>
          <h1 className="text-2xl md:text-3xl mb-4 font-semibold font-montserrat">
            Family Name Verification{" "}
          </h1>

          <div className="p-4 pt-10 border bg-gray-100 rounded-md">
            <div className="grid font-afcacad text-sm md:text-lg gap-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
              {/* First Name  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Name (1)<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="firstName.name"
                  value={information.firstName.name}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Gajendra Khadka"
                  aria-label="First Name input"
                  autoComplete="off"
                />
              </div>
              {/* first name checkbox  */}

              {/* first name checkbox */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Choose Option<span className="text-red-500">*</span>
                </label>
                <div className="flex gap-4 items-center h-[45px]">
                  <input
                    type="radio"
                    name="firstName.gender"
                    value="mr"
                    checked={information.firstName.gender === "mr"}
                    onChange={handleChange}
                    className="mt-1 border rounded-md scale-150"
                    aria-label="Gender input"
                    autoComplete="off"
                  />
                  <strong className="font-medium">Mr.</strong>

                  <input
                    type="radio"
                    name="firstName.gender"
                    value="mrs"
                    checked={information.firstName.gender === "mrs"}
                    onChange={handleChange}
                    className="mt-1 border rounded-md scale-150"
                    aria-label="Gender input"
                    autoComplete="off"
                  />
                  <strong className="font-medium">Mrs.</strong>

                  <input
                    type="radio"
                    name="firstName.gender"
                    value="ms"
                    checked={information.firstName.gender === "ms"}
                    onChange={handleChange}
                    className="mt-1 border rounded-md scale-150"
                    aria-label="Gender input"
                    autoComplete="off"
                  />
                  <strong className="font-medium">Miss.</strong>
                </div>
              </div>

              {/* Second Name  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Name (2)<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="secondName.name"
                  value={information.secondName.name}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Yubraj Basnet"
                  aria-label="Second Name input"
                  autoComplete="off"
                />
              </div>

              {/* Second name checkbox  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Choose Option<span className="text-red-500">*</span>
                </label>
                <div className="flex gap-4 items-center h-[45px]">
                  <input
                    type="radio"
                    name="secondName.gender"
                    value="mr"
                    checked={information.secondName.gender === "mr"}
                    onChange={handleChange}
                    className="mt-1 border rounded-md scale-150"
                    aria-label="Gender input"
                    autoComplete="off"
                  />
                  <strong className="font-medium">Mr.</strong>

                  <input
                    type="radio"
                    name="secondName.gender"
                    value="mrs"
                    checked={information.secondName.gender === "mrs"}
                    onChange={handleChange}
                    className="mt-1 border rounded-md scale-150"
                    aria-label="Gender input"
                    autoComplete="off"
                  />
                  <strong className="font-medium">Mrs.</strong>

                  <input
                    type="radio"
                    name="secondName.gender"
                    value="ms"
                    checked={information.secondName.gender === "ms"}
                    onChange={handleChange}
                    className="mt-1 border rounded-md scale-150"
                    aria-label="Gender input"
                    autoComplete="off"
                  />
                  <strong className="font-medium">Miss.</strong>
                </div>
              </div>

              {/* Citizenship Number  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Citizenship Number
                  <span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="citizenshipNumber"
                  value={information.citizenshipNumber}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="0000-000-0000"
                  aria-label="citizenshipNumber Name input"
                  autoComplete="off"
                />
              </div>

              {/* Date  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Date<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="date"
                  value={information.date}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: 20/01/2020"
                  aria-label="Date input"
                  autoComplete="off"
                />
              </div>

              {/* Municipality  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Municipality
                  <span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="municipality"
                  value={information.municipality}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Bharatpur"
                  aria-label="Municipality Name input"
                  autoComplete="off"
                />
              </div>
              {/* Ward No  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Ward No.
                  <span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="wardNo"
                  value={information.wardNo}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="06"
                  aria-label="Ward Name input"
                  autoComplete="off"
                />
              </div>
              {/* District  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  District
                  <span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="district"
                  value={information.district}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Bhaktapur"
                  aria-label="District Name input"
                  autoComplete="off"
                />
              </div>
              {/* Province  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Province
                  <span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="province"
                  value={information.province}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Bagmati"
                  aria-label="Province Name input"
                  autoComplete="off"
                />
              </div>

              {/* Name of Ward Chairperson  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Ward Chairperson Name
                  <span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="nameOfWardChairPerson"
                  value={information.nameOfWardChairPerson}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Raju Prasad Neupane"
                  aria-label="Ward Chairperson Name input"
                  autoComplete="off"
                />
              </div>
              {/* ward chairperson email  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Ward Chairperson Email
                  <span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="email"
                  name="wardChairPersonEmail"
                  value={information.wardChairPersonEmail}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: nawaraj@gmail.com"
                  aria-label="Ward Chairperson Email input"
                  autoComplete="off"
                />
              </div>
              {/* ward chairperson Phone  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Ward Chairperson Phone
                  <span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="wardChairPersonPhone"
                  value={information.wardChairPersonPhone}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: 9842000000"
                  aria-label="Ward Chairperson Phone input"
                  autoComplete="off"
                />
              </div>


            </div>
          </div>
        </div>
        <div className="p-4 border bg-white rounded-md">
          <h2 className="text-2xl font-semibold mb-10">Document Preview</h2>
          <div ref={previewRef} className="p-4 A4-size font-montserrat">
            <div className="h-[200px] w-full"></div>
            <p className="text-end font-bold">
              Date:{" "}
              {information.date
                ? information.date
                : "................................."}
            </p>
            <div className="text-center pt-10">
              <p className="text-xl underline font-bold">
                Subject: Name Verification
              </p>
            </div>
            <div className="pt-10 text-justify">
              This is to certify that{" "}
              <strong>
                {" "}
                {!information.firstName || !information.firstName.gender
                  ? "Mr./Mrs./Miss................"
                  : information.firstName.name &&
                    information.firstName.gender === "mr"
                    ? `Mr. ${information.firstName.name}`
                    : information.firstName.name &&
                      information.firstName.gender === "mrs"
                      ? `Mrs. ${information.firstName.name}`
                      : information.firstName.name &&
                      information.firstName.gender === "ms" &&
                      `Miss. ${information.firstName.name}`}
              </strong>{" "}
              is a permanent resident of
              <strong>
                {" "}
                {
                  information.municipality && information.wardNo && information.district && information.province ? information.municipality + ", " + information.wardNo + ", " + information.district + ", " + information.province + ", Nepal." : "................................................................"
                } {" "}
              </strong>

              {
                information.firstName.gender === "mr" ? "His" :
                  information.firstName.gender === "mrs" || information.firstName.gender === "ms" ? "Her" : "His/Her"
              }
              {" "}
              name is written as {" "}
              <strong>
                {information.secondName.name ? information.secondName.name : "................................................................"}
              </strong>
              {" "}
              in
              {" "}
              {
                information.firstName.gender === "mr" ? "his" :
                  information.firstName.gender === "mrs" || information.firstName.gender === "ms" ? "her" : "his/her"
              }
              {" "}
              passport and resident identity.
              {" "}
              {
                information.firstName.gender === "mr" ? "He" :
                  information.firstName.gender === "mrs" || information.firstName.gender === "ms" ? "She" : "He/She"
              }
              {" "}
              is the same person with Citizenship Number
              {" "}
              <strong>
                {
                  information.citizenshipNumber ? information.citizenshipNumber : "................................................................"
                }
              </strong>

              . According to the official record in Nepal.
              {" "}
              <strong>

                {
                  information.firstName.name ? information.firstName.name : "................................................................"
                }               </strong>
              can be written as <strong> {
                information.secondName.name ? information.secondName.name : "................................................................"
              }</strong> legally. Both the names are identical and belong to the same person.


            </div>

            <div className="pt-32">
              <div className=" flex flex-col items-end">
                <div className="w-48 h-0.5 my-2 bg-black"></div>
                <strong>
                  {information.nameOfWardChairPerson
                    ? information.nameOfWardChairPerson
                    : "..........................................................."}
                </strong>
                <p className="font-bold">Ward Chairperson</p>
                <p>
                  {information.wardChairPersonPhone
                    ? information.wardChairPersonPhone
                    : "..........................................................."}
                </p>
                <p>
                  {information.wardChairPersonEmail
                    ? information.wardChairPersonEmail
                    : "..........................................................."}
                </p>
              </div>
            </div>
          </div>
        </div>
        <button
          onClick={() => {
            handlePrint();
          }}
          className={`px-4 mt-2 py-2 bg-[#eb4934] hover:bg-[#d43b2e] text-white rounded-md cursor-pointer`}
        >
          Download PDF
        </button>
      </div>
    </>
  );
};

export default NameVerification;
